<template>
  <div>
    <div class="header">
      <PageTitle title="Controle de estoque" />
      <div class="nav-general">
        <b-nav-item
          @click="goToRoute('estoqueInstrumento')"
          :class="nav.route === 'estoqueInstrumento' ? 'active' : 'disabled'"
        >
          Estoque de instrumentos
        </b-nav-item>
        <b-nav-item
          @click="goToRoute('listaInstrumento')"
          :class="nav.route === 'listaInstrumento' ? 'active' : 'disabled'"
        >
          Lista de instrumentos
        </b-nav-item>
        <b-nav-item @click="goToRoute('reposicao')" :class="nav.route === 'reposicao' ? 'active' : 'disabled'">
          Reposições pendentes
        </b-nav-item>
      </div>
    </div>
    <cardless-base>
      <div class="nav-container">
        <transition name="fade" mode="out-in">
          <component
            v-bind:is="component[nav.route]"
            :filtersLista="filtersLista"
            :tipoItemSelect="tipoItemSelect"
            @goToListaInstrumentoAndFilter="goToListaInstrumentoAndFilter"
            @update-filters-lista="updateFiltersLista"
            @update-tipo-item-select="updateTipoItemSelect"
          />
        </transition>
      </div>
    </cardless-base>
  </div>
</template>

<script>
import CardlessBase from '@/templates/CardlessBase';
import PageTitle from '@/templates/PageTitle';
import EstoqueInstrumento from './Components/EstoqueInstrumento';
import Reposicao from './Components/Reposicao';
import ListaInstrumento from './Components/ListaInstrumento';

export default {
  components: {
    CardlessBase,
    PageTitle,
    EstoqueInstrumento,
    Reposicao,
    ListaInstrumento,
  },

  data() {
    return {
      nav: {
        route: 'estoqueInstrumento',
      },
      component: {
        estoqueInstrumento: 'EstoqueInstrumento',
        listaInstrumento: 'ListaInstrumento',
        reposicao: 'Reposicao',
      },
      filtersLista: {
        inEstoque: true,
      },
      tipoItemSelect: null,
    };
  },

  mounted() {
    if (this.$route.query.routeTipo) this.goToRoute(this.$route.query.routeTipo);
  },

  methods: {
    editRouteIndex() {
      return true;
    },

    goToRoute(route) {
      if (route === 'listaInstrumento') this.filtersLista = { inEstoque: true };
      this.tipoItemSelect = null;
      this.nav.route = route;
    },

    updateFiltersLista(prop, value) {
      this.filtersLista = {
        ...this.filtersLista,
        [prop]: value,
      };
    },

    updateTipoItemSelect(tipoItem) {
      this.tipoItemSelect = tipoItem;
    },

    async goToListaInstrumentoAndFilter(v) {
      this.updateFiltersLista('id_tipo_item', v.tipoItem.id_tipo_item);
      this.updateTipoItemSelect(v.tipoItem);
      this.nav.route = 'listaInstrumento';
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #fff;
  margin-top: -2rem;
  padding: 2rem 4rem;
  border-bottom: 1px solid #CFD1E6;
  margin-bottom: 3rem;
}
.nav-general {
  list-style: none;
  display: flex;
  position: absolute;
  top: 12rem;
  height: 2.875rem;
}
.active {
  border-bottom: 6px solid #209F85;
}
.active a{
  color:#209F85;
}
b-nav-item {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.75px;
  text-align: center;
}
.disabled a{
  color: #7E829F;
}
.nav-container {
  transition: margin-left 0.3s ease-in-out;
}
</style>
