<template>
  <div class="estoque-body">
    <GenericTable
      ref="lista"
      name="instrumento"
      title="Instrumento"
      add-route="instrumentoAdicionar"
      edit-route=""
      :disabledTitle="true"
      :cols="[
        'Tipo de Instrumento',
        'Quantidade',
      ]"
      :cols-name="[
        'descricao',
        'quantidade',
      ]"
      :cols-map="
        (i) => [
          i.descricao,
          i.quantidade,
        ]
      "
      :route="itemRoute"
      :permissionsToWrite="['rw_material']"
      :filters="filters"
      idKey="id_item"
      :hasPagination="true"
      :noadd="noAdd"
      :hasDetalhes="true"
      @showDetalhes="showDetalhes"
    >
      <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Tipo de Instrumento</label>
              <GenericSelectSearch
                route="tipoItem/listWithSearch"
                name="tipoItem"
                labelKey="descricao"
                idKey="id_tipo_item"
                @input="instrumentoSelect"
                :value="this && this.filters && this.filters.tipoItem"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>
  </div>
</template>

<script>
import GenericSelectSearch from '@/components/Form/GenericSelectSearch';

export default {
  components: {
    GenericSelectSearch,
  },

  data() {
    return {
      itemRoute: 'item/estoque',
      filters: {
        id_tipo_item: null,
      },
      noAdd: false,
    };
  },

  methods: {
    instrumentoSelect(itemSelected) {
      this.filters.id_tipo_item = itemSelected ? itemSelected.id_tipo_item : null;
      this.filters.tipoItem = itemSelected;
    },
    showDetalhes(v) {
      this.$emit('goToListaInstrumentoAndFilter', v);
    },
  },
};
</script>
