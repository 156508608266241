var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"estoque-body"},[_c('GenericTable',{ref:"lista",attrs:{"name":"instrumento","title":"Instrumento","add-route":"instrumentoAdicionar","edit-route":"instrumentoEditar","disabledTitle":true,"cols":[
      'Code',
      'Tipo de Instrumento',
      'Qtd de peças' ],"cols-name":[
      'code',
      'descricao',
      'quantidade_pecas' ],"cols-map":function (i) { return [
        i.code,
        i.descricao,
        i.quantidade_pecas ]; },"route":"item","permissionsToWrite":['rw_material'],"filters":_vm.filtersLista,"idKey":"id_item","hasPagination":"","noadd":false},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Tipo de Instrumento")]),_c('GenericSelectSearch',{attrs:{"route":"tipoItem/listWithSearch","name":"tipoItem","labelKey":"descricao","value":_vm.tipoItemSelect,"idKey":"id_tipo_item"},on:{"input":_vm.instrumentoSelect}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v("QR Code")]),_c('b-form-input',{staticClass:"invision-input sm",attrs:{"value":_vm.filtersLista.code,"autocomplete":"off"},on:{"input":function (v) { return _vm.updateFilters('code', v); }}})],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }